<template>
  <v-expansion-panels
    v-model="collapsed"
  >
    <v-expansion-panel>
      <v-expansion-panel-header>
        <div class="text-h6">{{ this.$t('exp_modem_iface.card_label') }}</div>
        <div class="pr-3" align="end">
          <v-icon color="rgb(8, 253, 8)" small>
            {{ signalIcon }}
          </v-icon>
          {{ iface.connection_mode }}
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="px-5">
        <v-divider></v-divider>
        <rowIpSettings
          :ipSettings="ipSettings"
          :readonly="!isAdministrator"
          @save="saveIpSettings"
        />
        <v-divider></v-divider>
        <rowDnsSettings
          :dnsSettings="dnsSettings"
          :readonly="!isAdministrator"
          @save="saveDnsSettings"
        />
        <v-divider></v-divider>
        <rowApnSettings
          :apnSettings="apnSettings"
          :readonly="!isAdministrator"
          @save="saveAppSettings"
        ></rowApnSettings>
        
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import rowApnSettings from './RowApnSettings.vue'
import rowIpSettings from './RowIpSettings.vue'
import rowDnsSettings from './RowDnsSettings.vue'


export default {
  name: 'modemIface',
  components: {
    rowApnSettings,
    rowIpSettings,
    rowDnsSettings,
  },
  data: () => ({
    /**
     * Определяет свернута ли каточка.
     * 0 свернута
     * 1 развернута
     */
    collapsed: 0,
    /**
     * Справочник надписей для отображения способа назначения.
     */
    assignmentLabel: {
      'manual': 'Manual',
      'dhcp': 'Automatic (DHCP)'
    },
  }),
  computed: {
    /**
     * Является ли пользователь администратором
     * @returns {bool}
     */
    isAdministrator() {
      return this.$store.getters.isAdministrator
    },
    /**
     * Валидна ли лицензия.
     * @returns {bool}
     */
    isLicenseValid() {
      return this.$store.getters['license/isLicenseValid']
    },
    /**
     * Текущие настройки интерфейса.
     */
    iface() {
      return this.$store.state.network.ifaceModem
    },
    /**
     * Выбор иконки уровня сигнала.
     * @returns {string}
     */
    signalIcon() {
      let signalStrength = Number(this.iface.signal_quality)
      if (signalStrength > 0 && signalStrength < 20) {
        return '$VeryLowSignalIcon'
      } else if (signalStrength >= 20 && signalStrength < 40) {
        return '$LowSignalIcon'
      } else if (signalStrength >= 40 && signalStrength < 60) {
        return '$MiddleSignalIcon'
      } else if (signalStrength >= 60 && signalStrength < 80) {
        return '$GoodSignalIcon'
      } else if (signalStrength >= 80) {
        return '$FullSignalIcon'
      }
      return '$NoSignalIcon'
    },
    /**
     * Настройки ip адресации.
     * @returns {object}
     */
    ipSettings() {
      return {
        ip_assign_method: this.iface.ip_assign_method,
        ip: this.iface.ip,
        netmask: this.iface.netmask,
        gateway: this.iface.gateway,
        mac_address: this.iface.mac_address,
      }
    },
    /**
     * Настройки apn подключения.
     * @returns {object}
     */
    apnSettings() {
      return {
        apn: this.iface.apn,
        username: this.iface.username,
        password: this.iface.password,
        connection_status: this.iface.connection_status,
      }
    },
    /**
     * Настройки dns серверов.
     * @returns {object}
     */
    dnsSettings() {
      return {
        dns_assign_method: this.iface.dns_assign_method,
        dns_servers: Array.isArray(this.iface.dns_servers) ? this.iface.dns_servers : [],
      }
    },
  },
  methods: {
    /**
     * Сохраняет настройки apn подключения.
     * @param {*} editedAppSettings 
     */
    saveAppSettings(editedAppSettings) {
      this.$store.dispatch('network/updateApnCredential', editedAppSettings)
    },
    /**
     * Сохраняет настройки ip адресации
     * @param {*} editedIpSettings 
     */
    saveIpSettings(editedIpSettings) {
      this.$store.dispatch(
        'network/updateIpCredential',
        {iface: 'ifaceModem', newIpCredential: editedIpSettings}
      )
    },
    /**
     * Сохраняет настройки dns серверов.
     * @param {*} editedDnsSettings 
     */
    saveDnsSettings(editedDnsSettings) {
      this.$store.dispatch(
        'network/updateDnsCredential',
        {iface: 'ifaceModem', newDnsCredential: editedDnsSettings}
      )
    },
  },
}

</script>