<template>
  <v-row class="py-7" align="center">
    <v-col cols="9" align="start">
      <RowInterfaceSetting 
        v-for="(value, _, index) in settings" :key="index"
        :title="value.label"
        :value="value.value"
      ></RowInterfaceSetting>
    </v-col>
    
    <v-col cols="3"  align="end" class="px-0 py-0">
      <v-btn 
        v-if="!readonly"
        @click="openEditSettingsDialog"
      >
        {{ $t('buttons.edit') }}
      </v-btn>
    </v-col>



    <v-dialog
      v-model="editSettingsDialog"
      persistent
      max-width="400px"
      class="rounded-xl"
    >
      <v-card
      class="rounded-xl"
      >
        <v-card-title>
          <span class="text-h5">{{ $t('row_apn_settings.card_title') }}</span>
        </v-card-title>
        <v-card-text>
          <div class="px-3 py-3">
            <v-form
             v-model="editSettingsForm"
             ref="form"
            >
              <v-row>
                <v-text-field
                  v-model="settingsEdited.apn"
                  :label=settings.apn.label
                  :rules="[rules.required]"
                ></v-text-field>
              </v-row>
              <v-row>
                <v-text-field
                  v-model="settingsEdited.username"
                  :label=settings.username.label
                ></v-text-field>
              </v-row>
              <v-row>
                <v-text-field
                  v-model="settingsEdited.password"
                  :label=settings.password.label
                ></v-text-field>
              </v-row>
            </v-form>
          </div>
          <small>*{{ $t('form_required_label') }}</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary--text"
            @click="cancelSettings"
          >
            {{ $t('buttons.cancel') }}
          </v-btn>
          <v-btn
            color="primary--text"
            :disabled="!isFormValid"
            @click="saveSettings"
          >
            {{ $t('buttons.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import RowInterfaceSetting from './RowInterfaceSetting.vue';

export default {
  name: 'ApnSettingsRow',
  components: {
    RowInterfaceSetting,
  },
  props: {
    apnSettings: {
      type: Object,
      default: () => {
        return {
            'apn': '',
            'username': '',
            'password': '',
            'connection_status': 'disconnected'
        }
      }
    },
    readonly: {
      type: Boolean,
      default: false,
    }
  },
  data: function () {
    return {
      editSettingsDialog: false,
      editSettingsForm: false,
      /**
       * Текущие редактируемые настройки.
       */
      settingsEdited: {
        'apn': '',
        'username': '',
        'password': '',
      },
      /**
       * Предустановленные настройки для последующего редактирования.
       */
      settingsEditedDefault: {
        'apn': '',
        'username': '',
        'password': '',
      },
    }
  },
  computed: {
    /**
     * Правила для валидации формы.
     */
    rules() {
      return {
        required: value => !!value || this.$t('form_validation_errors.required'),
      }
    },
    /**
     * Валидна ли форма
     * @returns {boolean}
     */
    isFormValid() {
      return this.editSettingsForm && this.isFormChanged
    },
    /**
     * Изменены ли значения в форму по сравнению с уже установленными.
     * @returns {boolean}
     */
    isFormChanged() {
      return !(Object.entries(this.settingsEdited).toString() === Object.entries(this.apnSettings).toString())
    },
    /**
     * Варианты статуса подключения
     */
    conStatusVariants() {
      return [
        {label: this.$t('row_apn_settings.connection_status_connected_label'), value: 'connected'},
        {label: this.$t('row_apn_settings.connection_status_disconnected_label'), value: 'disconnected'},
      ]
    },
    /**
     * Текущий статус подключения с label для отображения.
     * @returns {object}
     */
    conStatus() {
      let conn_status
      if (this.apnSettings.connection_status) {
        conn_status = this.conStatusVariants.find(element => element.value === this.apnSettings.connection_status)
      } else {
        conn_status = this.conStatusVariants.find(element => element.value === 'disconnected')
      }
      return conn_status
    },
    /**
     * Настройки вместе с их именами для отображения.
     * @returns {object}
     */
    settings() {
      return {
        apn: {label: this.$t('row_apn_settings.apn_label'), value: this.apnSettings.apn},
        username: {label: this.$t('row_apn_settings.username_label'), value: this.apnSettings.username},
        password: {label: this.$t('row_apn_settings.password_label'), value: this.apnSettings.password},
        connection_status: {label: this.$t('row_apn_settings.connection_status_label'), value: this.conStatus.label},
      }
    }
  },
  methods: {
    /**
     * Открывает диалог настройки.
     * @returns {void}
     */
    openEditSettingsDialog() {
      this.settingsEdited = {...this.apnSettings}
      this.editSettingsDialog = true
    },
    /**
     * Закрывает диалог настройки ip адресации
     * @returns {void}
     */
    closeEditSettingsDialog(){
      this.editSettingsDialog = false
      this.settingsEdited = {...this.settingsEditedDefault}
      this.$refs.form.reset()
    },
    /**
     * Генерирует событие save c новыми настройками и закрывает диалог.
     * @returns {void}
     */
    saveSettings() {
      this.$emit('save', this.settingsEdited)
      this.closeEditSettingsDialog()
    },
    /**
     * Генерирует событие cancel и закрывает диалог.
     * @returns {void}
     */
    cancelSettings() {
      this.$emit('cancel', )
      this.closeEditSettingsDialog()
    }
  }
}

</script>