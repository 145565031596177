<template>
  <div>
    <v-row class="py-5">
      <v-col
       cols="12" sm="12" lg="6"
       align="center"
      >
        <v-card 
          outlined
          elevation="4"
          width="95%"
        >
          <ExpEthernetIface/>
        </v-card>
      </v-col>
      <v-col
        v-if="isModemPresent"
        cols="12" sm="12" lg="6"
        align="center"
      >
        <v-card 
          outlined
          elevation="4"
          width="95%"
        >
          <ExpModemIface/>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ExpEthernetIface from '../components/NetworkInterfaceComponents/ExpEthernetIface.vue'
import ExpModemIface from '../components/NetworkInterfaceComponents/ExpModemIface.vue'

export default {
  components: {
    ExpModemIface,
    ExpEthernetIface
},
  data: () => ({
  }),
  computed: {
    /**
     * Является ли пользователь администратором.
     * @returns {boolean}
     */
    isAdministrator() {
      return this.$store.getters.isAdministrator
    },
    /**
     * Валидна ли лицензия.
     * @returns {boolean}
     */
    isLicenseValid() {
      return this.$store.getters['license/isLicenseValid']
    },
    /**
     * Установлен ли на устройстве модем.
     * @returns {boolean}
     */
    isModemPresent() {
      return this.$store.state.network.isModemPresent
    },
  },
  methods: {
  },
  created () {
    // Подключаемся к websocket для оперативного обновления параметров интерфейсов.
    this.$store.dispatch('network/connectToIfaceWiredSettingsWebsocket')
    this.$store.dispatch('network/connectToIfaceModemSettingsWebsocket')
  },
  destroyed () {
    // Отключается от websocket'ов обновления информации об интерфейсах.
    this.$store.dispatch('network/disconnectFromIfaceWiredSettingsWebsocket')
    this.$store.dispatch('network/disconnectFromIfaceModemSettingsWebsocket')
  },
}
</script>