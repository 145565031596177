<template>
    <v-row>
      <v-col class="font-weight-regular py-0" lg="6" xl="4">
        {{ title }}
      </v-col>
      <v-col class="font-weight-light py-0">
        <div v-if="value instanceof Array">
          <div v-for="(item, index) in value" :key="index">
            {{ item }}
          </div>
        </div>
        <div v-else>{{ value }}</div>
      </v-col>
    </v-row>
</template>

<script>
export default {
  name: 'OneSettingRow',
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      required: false,
      default: '',
    },
  }
}

</script>