<template>
  <v-expansion-panels
    v-model="collapsed"
  >
    <v-expansion-panel>
      <v-expansion-panel-header justify="space-between">
        <div class="text-h6">Ethernet</div>
        <div class="pr-3" align="end">
          <v-icon :color="colorStatus()" small>
            mdi-circle
          </v-icon>
          {{ iface.status }}
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="px-5">
        <v-divider></v-divider>
        <ipSettingsRow
          :ipSettings="ipSettings"
          :readonly="!isAdministrator"
          @save="saveIpSettings"
        />
        <v-divider></v-divider>
        <RowDnsSettings
          :dnsSettings="dnsSettings"
          :readonly="!isAdministrator"
          @save="saveDnsSettings"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import ipSettingsRow from './RowIpSettings.vue'
import RowDnsSettings from './RowDnsSettings.vue'


export default {
  name: 'wiredIface',
  components: {
    ipSettingsRow,
    RowDnsSettings,
},
  data: () => ({
    /**
     * Определяет свернута ли каточка.
     * 0 свернута
     * 1 развернута
     */
    collapsed: 0,
    /**
     * Справочник надписей для отображения способа назначения.
     */
    assignmentLabel: {
      'manual': 'Manual',
      'dhcp': 'Automatic (DHCP)'
    },
  }),
  computed: {
    /**
     * Является ли пользователь администратором
     * @returns {bool}
     */
    isAdministrator() {
      return this.$store.getters.isAdministrator
    },
    /**
     * Валидна ли лицензия.
     * @returns {bool}
     */
    isLicenseValid() {
      return this.$store.getters['license/isLicenseValid']
    },
    /**
     * Текущие настройки интерфейса.
     */
    iface() {
      return this.$store.state.network.ifaceWired
    },
    /**
     * Настройки ip адресации.
     * @returns {object}
     */
    ipSettings() {
      return {
        ip_assign_method: this.iface.ip_assign_method,
        ip: this.iface.ip,
        netmask: this.iface.netmask,
        gateway: this.iface.gateway,
        mac_address: this.iface.mac_address,
      }
    },
    /**
     * Настройки dns серверов.
     * @returns {object}
     */
    dnsSettings() {
      return {
        dns_assign_method: this.iface.dns_assign_method,
        dns_servers: Array.isArray(this.iface.dns_servers) ? this.iface.dns_servers : [],
      }
    },
  },
  methods: {
    /**
     * Сохраняет настройки ip адресации
     * @param {*} editedIpSettings 
     */
    saveIpSettings(editedIpSettings) {
      this.$store.dispatch(
        'network/updateIpCredential',
        {iface: 'ifaceWired', newIpCredential: editedIpSettings}
      )
    },
    /**
     * Сохраняет настройки dns серверов.
     * @param {*} editedDnsSettings 
     */
    saveDnsSettings(editedDnsSettings) {
      this.$store.dispatch(
        'network/updateDnsCredential',
        {iface: 'ifaceWired', newDnsCredential: editedDnsSettings}
      )
    },
    /**
     * Цвет иконки индикации интерфейса.
     * @returns {string}
     */
    colorStatus() {
      if (this.iface.status === 'up') {
        return 'rgb(8, 253, 8)'
      } else {
        return 'rgb(221, 230, 221)'
      }
    },
  },
}

</script>