<template>
  <v-row class="py-7" align="center">
    <v-col cols="9" align="start">
      <RowInterfaceSetting 
        :title="settings.dns_assign_method.label"
        :value="settings.dns_assign_method.value"
      ></RowInterfaceSetting>
      <RowInterfaceSetting 
        :title="settings.dns_servers.label"
        :value="settings.dns_servers.value"
      ></RowInterfaceSetting>
    </v-col>
    
    <v-col cols="3" align="end" class="px-0 py-0">
      <v-btn 
        v-if="!readonly"
        @click="openEditSettingsDialog"
      >
        {{ $t('buttons.edit') }}
      </v-btn>
    </v-col>
    <v-dialog
      v-model="editSettingsDialog"
      persistent
      max-width="400px"
      class="rounded-xl"
    >
      <v-card
      class="rounded-xl"
      >
        <v-card-title>
          <span class="text-h5">{{ $t('row_dns_settings.card_title') }}</span>
        </v-card-title>
        <v-card-text>
          <div class="px-3 py-3">
            <v-form
              ref="form"
              v-model="editSettingsForm"
            >
              <v-row>
                <v-select
                  v-model="settingsEdited.dns_assign_method"
                  :label=settings.dns_assign_method.label
                  :items="assignmentVariants"
                  item-text="label"
                  item-value="value"
                  @change="changeAssignmentMode"
                  :rules="[rules.required]"
                />
              </v-row>
              <v-row v-if="isAssignmentManual">
                <v-text-field
                  v-model="settingsEdited.dnsPrimary"
                  :label=settings.dnsPrimary.label
                  :rules="[rules.ip]"
                ></v-text-field>
              </v-row>
              <v-row v-if="isAssignmentManual">
                <v-text-field
                  v-model="settingsEdited.dnsSecondary"
                  :label=settings.dnsSecondary.label
                  :rules="[rules.ip]"
                ></v-text-field>
              </v-row>
            </v-form>
          </div>
          <small>*{{ $t('form_required_label') }}</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary--text"
            @click="cancelSettings"
          >
            {{ $t('buttons.cancel') }}
          </v-btn>
          <v-btn
            color="primary--text"
            :disabled="!isFormValid"
            @click="saveSettings"
          >
            {{ $t('buttons.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>


<script>
import RowInterfaceSetting from './RowInterfaceSetting.vue';

export default {
  name: 'DnsSettingsRow',
  components: {
    RowInterfaceSetting,
  },
  props: {
    dnsSettings: {
      type: Object,
      default: () => {
        return {
          dns_assign_method: 'dhcp',
          dns_servers: [],
        }
      }
    },
    readonly: {
      type: Boolean,
      default: false,
    }
  },
  data: function () {
    return {
      editSettingsDialog: false,
      editSettingsForm: false,
      /**
       * Текущие редактируемые настройки.
       */
      settingsEdited: {
        'dns_assign_method': '',
        'dnsPrimary': '',
        'dnsSecondary': '',
      },
      /**
       * Предустановленные настройки для последующего редактирования.
       */
      settingsEditedDefault: {
        'dns_assign_method': '',
        'dnsPrimary': '',
        'dnsSecondary': '',
      },
    }
  },
  computed: {
    /**
     * Правила для валидации формы.
     */
    rules() {
      return {
        required: value => !!value || this.$t('form_validation_errors.required'),
        ip: value => {
          const pattern = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
          return !value || pattern.test(value) || this.$t('form_validation_errors.invalid_ip')
        },
      }
    },
    /**
     * Валидна ли форма
     * @returns {boolean}
     */
    isFormValid() {
      return this.editSettingsForm && this.isFormChanged
    },
    /**
     * Назначаются ли настройки dns вручную.
     * @returns {boolean}
     */
    isAssignmentManual() {
      return this.settingsEdited.dns_assign_method === 'manual'
    },
    /**
     * Изменены ли значения в форму по сравнению с уже установленными.
     * @returns {boolean}
     */
    isFormChanged() {
      return !(Object.entries(this.settingsEdited).toString() === Object.entries(this.dnsSettings).toString())
    },
    /**
     * Варианты назначения
     */
    assignmentVariants() {
      return [
        {label: this.$t('assignment_variants.manual_label'), value: 'manual'},
        {label: this.$t('assignment_variants.automatic_label'), value: 'dhcp'},
      ]
    },
    /**
     * Настройки dns вместе с их именами для отображения.
     * @returns {object}
     */
    settings() {
      let assignment = this.assignmentVariants.find(element => element.value === this.dnsSettings.dns_assign_method)
      return {
        dns_assign_method: {label: this.$t('row_dns_settings.dns_assign_method_label'), value: assignment.label},
        dnsPrimary: {label: this.$t('row_dns_settings.primary_dns_server_label'), value: this.dnsSettings.dns_servers[0]},
        dnsSecondary: {label: this.$t('row_dns_settings.secondary_dns_server_label'), value: this.dnsSettings.dns_servers[1]},
        dns_servers: {label: this.$t('row_dns_settings.dns_servers_label'), value: this.dnsSettings.dns_servers}
      }
    }
  },
  methods: {
    /**
     * Сбрасывает редактируемы значения dns серверов при смене способа назначения.
     */
    changeAssignmentMode() {
      this.settingsEdited.dnsPrimary = ''
      this.settingsEdited.dnsSecondary = ''
    },
    /**
     * Открывает диалог настройки.
     * @returns {void}
     */
    openEditSettingsDialog() {
      this.settingsEdited = {
        dns_assign_method: this.dnsSettings.dns_assign_method,
        dnsPrimary: this.dnsSettings.dns_servers[0] || '',
        dnsSecondary: this.dnsSettings.dns_servers[1] || '',
      }
      this.editSettingsDialog = true
    },
    /**
     * Закрывает диалог настройки
     * @returns {void}
     */
    closeEditSettingsDialog(){
      this.editSettingsDialog = false
      this.settingsEdited = {...this.settingsEditedDefault}
      this.$refs.form.reset()
    },
    /**
     * Генерирует событие save c новыми настройками dns и закрывает диалог.
     * @returns {void}
     */
    saveSettings() {
      let dns_servers = []
      if (this.settingsEdited.dnsPrimary) { dns_servers.push(this.settingsEdited.dnsPrimary)}
      if (this.settingsEdited.dnsSecondary) { dns_servers.push(this.settingsEdited.dnsSecondary)}
      let settingsEdited = {
        dns_assign_method: this.settingsEdited.dns_assign_method,
        dns_servers: dns_servers,
      }
      this.$emit('save', settingsEdited)
      this.closeEditSettingsDialog()
    },
    /**
     * Генерирует событие cancel и закрывает диалог.
     * @returns {void}
     */
    cancelSettings() {
      this.$emit('cancel', )
      this.closeEditSettingsDialog()
    }
  }
}

</script>