<template>
  <v-row class="py-7" align="center">
    <v-col cols="9" align="start">
      <RowInterfaceSetting 
        v-for="(value, _, index) in settings" :key="index"
        :title="value.label"
        :value="value.value"
      ></RowInterfaceSetting>
    </v-col>
    
    <v-col cols="3"  align="end" class="px-0 py-0">
      <v-btn 
        v-if="!readonly"
        @click="openEditSettingsDialog"
      >
        {{ $t('buttons.edit') }}
      </v-btn>
    </v-col>
    <v-dialog
      v-model="editSettingsDialog"
      persistent
      max-width="400px"
      class="rounded-xl"
    >
      <v-card
      class="rounded-xl"
      >
        <v-card-title>
          <span class="text-h5">{{ $t('row_ip_settings.card_title') }}</span>
        </v-card-title>
        <v-card-text>
          <div class="px-3 py-3">
            <v-form
             v-model="editSettingsForm"
             ref="form"
            >
              <v-row>
                <v-select
                  v-model="settingsEdited.ip_assign_method"
                  :label=settings.ip_assign_method.label
                  :items="assignmentVariants"
                  item-text="label"
                  item-value="value"
                  @change="eraseIpAddressesSettings"
                  :rules="[rules.required]"
                />
              </v-row>
              <v-row v-if="isAssignmentManual">
                <v-text-field
                  v-model="settingsEdited.ip"
                  :label=settings.ip.label
                  :rules="[rules.required, rules.ip]"
                ></v-text-field>
              </v-row>
              <v-row v-if="isAssignmentManual">
                <v-text-field
                  v-model="settingsEdited.netmask"
                  :label=settings.netmask.label
                  :rules="[rules.required, rules.ip]"
                ></v-text-field>
              </v-row>
              <v-row v-if="isAssignmentManual">
                <v-text-field
                  v-model="settingsEdited.gateway"
                  :label=settings.gateway.label
                  :rules="[rules.ip]"
                ></v-text-field>
              </v-row>
            </v-form>
          </div>
          <small>*{{ $t('form_required_label') }}</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary--text"
            @click="cancelSettings"
          >
            {{ $t('buttons.cancel') }}
          </v-btn>
          <v-btn
            color="primary--text"
            :disabled="!isFormValid"
            @click="saveSettings"
          >
            {{ $t('buttons.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>


<script>
import RowInterfaceSetting from './RowInterfaceSetting.vue';

export default {
  name: 'IpSettingsRow',
  components: {
    RowInterfaceSetting,
  },
  props: {
    ipSettings: {
      type: Object,
      default: () => {
        return {
          ip_assign_method: 'dhcp',
          ip: '',
          netmask: '',
          gateway: '',
          mac_address: '',
        }
      }
    },
    readonly: {
      type: Boolean,
      default: false,
    }
  },
  data: function () {
    return {
      editSettingsDialog: false,
      editSettingsForm: false,
      /**
       * Текущие редактируемые настройки.
       */
      settingsEdited: {
        'ip_assign_method': '',
        'ip': '',
        'netmask': '',
        'gateway': '',
      },
      /**
       * Предустановленные настройки для последующего редактирования.
       */
      settingsEditedDefault: {
        'ip_assign_method': '',
        'ip': '',
        'netmask': '',
        'gateway': '',
      },
    }
  },
  computed: {
    /**
     * Правила для валидации формы.
     */
    rules() {
      return {
        required: value => !!value || this.$t('form_validation_errors.required'),
        ip: value => {
          const pattern = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
          return !value || pattern.test(value) || this.$t('form_validation_errors.invalid_ip')
        },
      }
    },
    /**
     * Валидна ли форма
     * @returns {boolean}
     */
    isFormValid() {
      return this.editSettingsForm && this.isFormChanged
    },
    /**
     * Изменены ли значения в форму по сравнению с уже установленными.
     * @returns {boolean}
     */
    isFormChanged() {
      return !(Object.entries(this.settingsEdited).toString() === Object.entries(this.ipSettings).toString())
    },
    /**
     * Назначаются ли настройки ip вручную.
     * @returns {boolean}
     */
    isAssignmentManual() {
      return this.settingsEdited.ip_assign_method === 'manual'
    },
    /**
     * Варианты назначения
     */
    assignmentVariants() {
      return [
        {label: this.$t('assignment_variants.manual_label'), value: 'manual'},
        {label: this.$t('assignment_variants.automatic_label'), value: 'dhcp'},
      ]
    },
    /**
     * Настройки вместе с их именами для отображения.
     * @returns {object}
     */
    settings() {
      let assignment = this.assignmentVariants.find(element => element.value === this.ipSettings.ip_assign_method)
      return {
        ip_assign_method: {label: this.$t('row_ip_settings.ip_assign_method_label'), value: assignment.label},
        ip: {label: this.$t('row_ip_settings.ip_label'), value: this.ipSettings.ip},
        netmask: {label: this.$t('row_ip_settings.netmask_label'), value: this.ipSettings.netmask},
        gateway: {label: this.$t('row_ip_settings.gateway_label'), value: this.ipSettings.gateway},
        mac_address: {label: this.$t('row_ip_settings.mac_address_label'), value: this.ipSettings.mac_address},
      }
    }
  },
  methods: {
    /**
     * Сбрасывает ip адреса на пустое значение.
     * @returns {void}
     */
     eraseIpAddressesSettings() {
      this.settingsEdited.ip = ''
      this.settingsEdited.netmask = ''
      this.settingsEdited.gateway = ''
    },
    /**
     * Открывает диалог настройки.
     * @returns {void}
     */
    openEditSettingsDialog() {
      for (let key in this.settingsEdited) {
        this.settingsEdited[key] = this.ipSettings[key]
      }
      this.editSettingsDialog = true
    },
    /**
     * Закрывает диалог настройки ip адресации
     * @returns {void}
     */
    closeEditSettingsDialog(){
      this.editSettingsDialog = false
      this.settingsEdited = {...this.settingsEditedDefault}
      this.$refs.form.reset()
    },
    /**
     * Генерирует событие save c новыми настройками и закрывает диалог.
     * @returns {void}
     */
    saveSettings() {
      this.$emit('save', this.settingsEdited)
      this.closeEditSettingsDialog()
    },
    /**
     * Генерирует событие cancel и закрывает диалог.
     * @returns {void}
     */
    cancelSettings() {
      this.$emit('cancel', )
      this.closeEditSettingsDialog()
    }
  }
}

</script>